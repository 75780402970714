<template>
  <div class="detalle-orden mx-2">
    <v-row>
      <v-col cols="12">
        <v-card v-if="orden.orden != undefined">
          <v-card-title>
            Orden de compra # {{ orden.orden.folio | folio }}
            <v-spacer></v-spacer>
            <v-btn-toggle mandatory v-model="toggle_exclusive" dense borderless>
              <v-btn
                class="white--text"
                color="primary"
                @click="$router.push('/ordenes')"
              >
                Lista ordenes
              </v-btn>
              <v-menu close-on-click offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    class="white--text"
                    v-on="on"
                    v-bind="attrs"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Acciones
                    <v-icon right color="white">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="changeState('cargar')"
                    v-if="orden.orden.estado == 0"
                  >
                    <v-list-item-title
                      >Cargar al stock
                      <v-icon right>mdi-upload</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="pdf()">
                    <v-list-item-title
                      >Descargar
                      <v-icon right>mdi-download</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="send()">
                    <v-list-item-title
                      >Enviar
                      <v-icon right>mdi-upload</v-icon></v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    @click="dialog = true"
                    v-if="
                      orden.orden.estado == 1 && orden.orden.factura == null
                    "
                  >
                    <v-list-item-title
                      >Asociar factura
                      <v-icon right>mdi-ticket</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="changeState('anular')"
                    v-if="orden.orden.estado != 2"
                  >
                    <v-list-item-title
                      >Anular
                      <v-icon right>mdi-cancel</v-icon></v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn-toggle>
          </v-card-title>
          <v-card-subtitle>
            <v-chip color="orange" dark label v-if="orden.orden.estado == 0"
              >Pendiente</v-chip
            >
            <v-chip color="success" dark label v-if="orden.orden.estado == 1"
              >Cargada</v-chip
            >
            <v-chip color="error" dark label v-if="orden.orden.estado == 2"
              >Anulada</v-chip
            >
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <p>
                      <strong>
                        Proveedor:
                      </strong>
                      {{ orden.orden.proveedor }}
                      <br />
                      <strong>Nit: </strong>
                      {{ orden.orden.nit_proveedor }}
                      <br />
                      <strong>Dirección: </strong>
                      {{ orden.orden.direccion }}
                      <br />
                      <strong>Teléfono: </strong>
                      {{ orden.orden.telefono }}
                      <br />
                      <strong>Regimen: </strong>
                      {{ orden.orden.regimen }}
                      <br />
                      <br />
                      <strong>
                        Stock de
                        <span v-if="orden.orden.stock == 1">Venta</span>
                        <span v-else>Consumo</span>
                      </strong>
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <p>
                      <strong>
                        Fecha orden:
                      </strong>
                      {{ moment(orden.orden.fecha).format("ll") }}
                      <br />
                      <strong>
                        Fecha carga:
                      </strong>
                      <span v-if="orden.orden.fecha_carga != null">
                        {{ moment(orden.orden.fecha_carga).format("ll") }}
                      </span>
                      <br />
                      <strong>Fecha vencimiento: </strong>
                      {{ moment(orden.orden.vencimiento).format("ll") }}
                      <br />
                      <strong>Fecha factura: </strong>
                      <span v-if="orden.orden.factura != null">{{
                        moment(orden.orden.fecha_factura).format("ll")
                      }}</span>
                      <span v-else></span>
                      <br />
                      <strong>N° factura: </strong>
                      {{ orden.orden.factura }}
                      <br />
                      <strong>Forma de pago: </strong>
                      <span v-if="orden.orden.metodo == 1">Contado</span>
                      <span v-else>Credito</span>
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <td colspan="3">
                              <v-text-field
                                class="mt-2"
                                outlined
                                rounded
                                dense
                                append-icon="mdi-magnify"
                                label="Buscar"
                                v-model="search"
                              ></v-text-field>
                            </td>
                            <td colspan="3">
                              <v-autocomplete
                                class="mt-2"
                                width="200"
                                outlined
                                rounded
                                dense
                                color="primary"
                                :items="productos"
                                item-color="primary"
                                :menu-props="{ maxHeight: '400' }"
                                label="Productos"
                                persistent-hint
                                return-object
                                @change="addProducto"
                                v-if="orden.orden.estado == 0"
                                ref="productos_lista"
                              ></v-autocomplete>
                            </td>
                            <td colspan="3" class="text-right">
                              <v-btn
                                v-if="orden.orden.estado == 0"
                                :dark="!loading"
                                :color="!loading ? 'black' : 'gray'"
                                :loading="loading"
                                :disabled="loading"
                                @click="actualizar()"
                              >
                                <v-icon left>mdi-content-save</v-icon>
                                Actualizar</v-btn
                              >
                            </td>
                          </tr>
                          <tr>
                            <th></th>
                            <th>Codigo</th>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Precio Uni</th>
                            <th>IVA</th>
                            <th>Subtotal</th>
                            <th>IVA</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in filtro" :key="index">
                            <td>
                              <v-btn
                                icon
                                color="error"
                                v-if="
                                  orden.entradas.length > 1 &&
                                    orden.orden.estado == 0
                                "
                                :loading="loading"
                                :disabled="loading"
                                @click="deleteItem(item)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </td>
                            <td>
                              {{ item.codigo }}
                            </td>
                            <td>
                              {{ item.producto }}
                            </td>
                            <td>
                              <v-text-field
                                style="width: 150px"
                                rounded
                                dense
                                outlined
                                v-model.number="item.cantidad"
                                type="number"
                                class="mt-4"
                                v-if="orden.orden.estado == 0"
                                @change="calculo(item)"
                              ></v-text-field>
                              <span v-else>
                                {{ item.cantidad }}
                              </span>
                            </td>
                            <td>
                              <v-text-field
                                style="width: 150px"
                                rounded
                                dense
                                outlined
                                v-model.number="item.precio"
                                type="number"
                                class="mt-4"
                                v-if="orden.orden.estado == 0"
                                @change="calculo(item)"
                              ></v-text-field>
                              <span v-else>
                                {{ item.precio | currency }}
                              </span>
                            </td>
                            <td>{{ item.iva }}%</td>
                            <td>
                              {{ item.subtotal | currency }}
                            </td>
                            <td>
                              {{ item.total_iva | currency }}
                            </td>
                            <td>
                              {{ item.total | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title>Totalización</v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>Subtotal</th>
                            <td>
                              {{ orden.orden.subtotal | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>IVA</th>
                            <td>
                              {{ orden.orden.iva | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <td>
                              {{ orden.orden.total | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title>Observación</v-card-title>
                  <v-card-text>
                    {{orden.orden.observacion}}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-dialog v-model="dialog" persistent scrollable max-width="400px">
              <v-card>
                <v-card-title>
                  <span class="headline">Asociar factura</span>
                </v-card-title>
                <v-card-text
                  ><v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        label="Numero de factura"
                        v-model="factura"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            dense
                            label="Fecha"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          scrollable
                          v-model="date"
                          locale="es-co"
                          color="primary"
                          first-day-of-week="1"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialog = false" color="primary">
                    Cancelar
                  </v-btn>
                  <v-btn color="black" dark @click="asociar()">
                    Asociar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "detalleOrden",
  data: () => ({
    factura: "",
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    dialog: false,
    toggle_exclusive: undefined,
    orden: {},
    search: "",
    loading: false,
    productos: [],
  }),
  methods: {
    loadDetalleOrden() {
      const body = {
        route: "/detalleorden",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.orden = {
              orden: response.data.data.orden,
              entradas: response.data.data.entradas,
            };
          }
        })
        .finally(() => this.loadProductos());
    },
    loadProductos() {
      const body = {
        route: "/productos_proveedor",
        params: {
          id: this.orden.orden.proveedor_id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.productos = response.data.data.filter((p) => {
            return parseInt(p.inventario) == this.orden.orden.stock;
          })
          
          this.productos = this.productos.map(p => {
            let text = `${p.producto} [${p.codigo}]`
            return Object.assign(p, {text})
          })

          this.orden.entradas.forEach((item) => {
            const index = this.productos.findIndex(
              (element) => element.id_activo == item.producto_id
            );
            this.productos.splice(index, 1);
          });
          this.productos = this.productos.filter((p) => {
            return parseInt(p.inventario) == this.orden.orden.stock;
          });
        }
      });
    },
    calculo(item) {
      item.precio = item.precio == "" ? 0 : item.precio;
      item.cantidad = item.cantidad == "" ? 0 : item.cantidad;
      let iva = Math.round(
        (((item.precio / ((100 + item.iva) / 100)) * item.iva) / 100) *
          item.cantidad
      );
      let subtotal = Math.round(
        (item.precio / ((100 + item.iva) / 100)) * item.cantidad
      );

      item.total_iva = iva;
      item.subtotal = subtotal;
      item.total = Math.round(subtotal + iva);
    },
    changeState(state) {
      let inicio_mes =
        this.orden.orden.fecha_carga != null
          ? moment(this.orden.orden.fecha_carga)
              .startOf("month")
              .format("YYYY-MM-DD")
          : null;
      const body = {
        route: "/change_state",
        data: {
          id: this.orden.orden.id,
          date: moment().format("YYYY-MM-DD"),
          inicio_mes,
          state: state,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.loadDetalleOrden();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
    asociar() {
      const body = {
        route: "/asociar_orden",
        data: {
          id: this.orden.orden.id,
          orden: {
            factura: this.factura,
            fecha_factura: this.date,
          },
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.dialog = false;
            this.$store.dispatch("setAlert", alert);
            this.loadDetalleOrden();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
    addProducto(input) {
      const entrada = {
        centro_id: this.orden.orden.centro_id,
        sede_id: this.orden.orden.sede_id,
        orden_id: this.orden.orden.id,
        producto_id: input.id_activo,
        producto: input.producto,
        codigo: input.codigo,
        cantidad: 0,
        iva: parseFloat(input.iva_compra),
        precio: parseFloat(input.compra),
        subtotal: 0,
        total_iva: 0,
        total: 0,
      };
      const body = {
        route: "/add_entrada",
        data: {
          entrada,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.loadDetalleOrden();
            this.$refs.productos_lista.reset()
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
    actualizar() {
      this.loading = true;
      this.search = "";

      const subtotal = this.filtro.reduce((index, item) => {
        return index + item.subtotal;
      }, 0);
      const iva = this.filtro.reduce((index, item) => {
        return index + item.total_iva;
      }, 0);
      const total = this.filtro.reduce((index, item) => {
        return index + item.total;
      }, 0);

      const body = {
        route: "/update_orden",
        data: {
          id: this.orden.orden.id,
          orden: {
            subtotal: subtotal,
            iva: iva,
            total: total,
          },
          entradas: this.filtro,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.loadDetalleOrden();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading = false));
    },
    deleteItem(entrada) {
      if (this.orden.entradas.length > 1) {
        // this.search = "";
        let i = this.orden.entradas.findIndex(
          (element) => element.id == entrada.id
        );
        this.orden.entradas.splice(i, 1);
        const subtotal = this.filtro.reduce((index, item) => {
          return index + item.subtotal;
        }, 0);
        const iva = this.filtro.reduce((index, item) => {
          return index + item.total_iva;
        }, 0);
        const total = this.filtro.reduce((index, item) => {
          return index + item.total;
        }, 0);
        this.loading = true;
        const body = {
          route: "/delete_entrada_oreden",
          data: {
            id: this.orden.orden.id,
            orden: {
              subtotal: subtotal,
              iva: iva,
              total: total,
            },
            id_delete: entrada.id,
          },
        };
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
              this.loadDetalleOrden();
            }
          })
          .catch((error) => {
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      }
    },
    moment(data) {
      return moment(data);
    },
    pdf() {
      this.loading = true;
      axios({
        url: "/PDF_orden",
        method: "GET",
        params: {
          id: this.$route.params.id,
        },
        headers: {
          "access-rec": atob(this.$store.state.access_token.access_token).split(
            "-_-"
          )[0],
          SSREC: this.$store.state.access_token.session_start,
        },
        responseType: "blob",
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.id = "fileLink";
          fileLink.href = fileURL;
          fileLink.target = "_blank";
          fileLink.setAttribute(
            "open",
            "orden_compra-" +
              this.$options.filters.folio(this.orden.orden.folio) +
              ".pdf"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          document.getElementById("fileLink").remove();
        })
        .finally(() => (this.loading = false));
    },
    send() {
      this.loading = true;
      const body = {
        route: "/send_PDF_orden",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          let alert = {
            alert: true,
            color: "",
            text: "",
          };
          if (response.data.success) {
            alert.color = "success";
          } else {
            alert.color = "error";
          }
          alert.text = response.data.data;
          this.$store.dispatch("setAlert", alert);
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    this.loadDetalleOrden();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    filtro() {
      return this.orden.entradas.filter((entrada) => {
        return (
          entrada.producto.toLowerCase().includes(this.search.toLowerCase()) ||
          entrada.codigo.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
};
</script>
